import { Box, Flex } from '@chakra-ui/layout'

import Error404Index from '../../errors/components/Error404Index'

import withLayout from 'modules/brand/hoc/withLayout'
import Footer from 'modules/pmall/components/HomeElements/Footer'
import TopNav from 'modules/pmall/components/HomeElements/TopNav'

const Error404 = () => (
  <Flex direction="column" h="100vh" justifyContent="space-between">
    <TopNav px={['1rem', '4rem', '4.75rem']} />

    <Error404Index redirectLink="/" />

    <Box>
      <Footer />
    </Box>
  </Flex>
)

export default withLayout(Error404, 'light', {
  pageType: 'error',
  bgColor: 'white',
  hasFooter: false,
  hasTopNav: false,
})
