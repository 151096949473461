import Error404Index from '../modules/pmall/components/Error404Index'
import Error404Brand from '../pages/[brand]/404'

import { selectBrand } from 'modules/brand/selectors'
import { useTypedSelector } from 'redux/store'

const Error404Page = () => {
  const brand = useTypedSelector(selectBrand())

  return brand ? <Error404Brand /> : <Error404Index />
}

export default Error404Page
