import { Box, Button, Flex, Text } from '@chakra-ui/core'
import Heading from 'modules/shared/components/Heading'
import dynamic from 'next/dynamic'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useProductRoutes } from '../../products/hooks'
import { LongArrowRight } from '../../shared/components/Icons/LongArrowRight'

const PageHeader = dynamic(() => import('../../shared/components/PageHeader'))
const NextLink = dynamic(() => import('../../shared/components/NextLink'))

interface SupportIndexProps {
  redirectLink?: string
}

const SupportIndex: React.FC<SupportIndexProps> = ({ redirectLink }) => {
  const { t } = useTranslation()
  const productsUrl = useProductRoutes()

  return (
    <PageHeader title={t('title:404', 'Page not Found')}>
      <Box>
        <Flex
          direction="column"
          align="center"
          justify="center"
          px={[6, 12, 40]}
          py={[20, 24, 40]}
          size="lg"
        >
          <Heading variant="h6">
            {t('common:404.page-not-found', '404 Page Not Found')}
          </Heading>
          <Text variant="cardBody" py={[8, 8, 4]} px={[1, null, 4]}>
            {t(
              'common:404.page-requested-does-not-exist',
              'The page you requested does not exist.'
            )}
          </Text>
          <NextLink href={redirectLink ?? productsUrl}>
            <Button variant="primary" textTransform="uppercase" px={[4, 4, 8]}>
              {t('common:continue-shopping', 'Continue Shopping')}
              <LongArrowRight size="1.5em" name="long-arrow-right" ml="4" />
            </Button>
          </NextLink>
        </Flex>
      </Box>
    </PageHeader>
  )
}

export default SupportIndex
